
// mixin

@mixin font1-wmd-fh1-lh126-tcenter {
    font-family: $font1;
    font-size: 64px;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: -1.1px;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh1-lh126 {
    font-family: $font1;
    font-size: 64px;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: -1px;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin mob-fh1 {
    font-family: $font1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.19;
    letter-spacing: -0.66px;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh2-lh123-ls5s-tcenter {
    font-family: $font1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: -0.5px;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh2-lh123-ls5 {
    font-family: $font1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: -0.5px;
    color: $dark-text;
  }
  
  @mixin mob-fh2-alt {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    color: $dark-text;
  }
  
  @mixin mob-fh2-tcenter-alt {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin mob-fh2-tcenter {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin mob-fh2 {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh4-lh1 {
    font-family: $font1;
    font-size: 22px;
    font-weight: 500;
    line-height: 0.91;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh4-lh14 {
    font-family: $font1;
    font-size: 22px;
    font-weight: 500;
    line-height: 0.91;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh3-lh11-tcenter {
    font-family: $font1;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fh3-lh11 {
    font-family: $font1;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: $dark-text;
  }
  
  @mixin font1-wrg-fh3-lh155 {
    font-family: $font1;
    font-size: 18px;
    line-height: 1.56;
    color: $lighter-text;
  }
  
  @mixin font1-wbold-fbody {
    font-family: $font1;
    font-size: 16px;
    font-weight: bold;
    color: $dark-text;
  }
  
  @mixin font1-wbold-fbody-tcenter {
    font-family: $font1;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wmd-fbody {
    font-family: $font1;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  
  @mixin font1-wrg-fbody-lh14-tcenter {
    font-family: $font1;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $dark-text;
  }
  
  @mixin font1-wrg-fbody-lh175-tcenter {
    font-family: $font1;
    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    color: #454545;
  }
  
  @mixin font1-wrg-fbody-lh14 {
    font-family: $font1;
    font-size: 16px;
    line-height: 1.5;
    color: $dark-text;
  }
  
  @mixin font2-wrg-fxs-lh17 {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.71;
    color: #262f33;
  }

//   Classes

.font1-wmd-fh1-lh126-tcenter {
    font-family: $font1;
    font-size: 64px;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: -1.1px;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wmd-fh1-lh126 {
    font-family: $font1;
    font-size: 64px;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: -1px;
    text-align: center;
    color: $dark-text;
  }
  
  .mob-fh1 {
    font-family: $font1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.19;
    letter-spacing: -0.66px;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wmd-fh2-lh123-ls5s-tcenter {
    font-family: $font1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: -0.5px;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wmd-fh2-lh123-ls5 {
    font-family: $font1;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: -0.5px;
    color: $dark-text;
  }
  
  .mob-fh2-alt {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    color: $dark-text;
  }
  
  .mob-fh2-tcenter-alt {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    text-align: center;
    color: $dark-text;
  }
  
  .mob-fh2-tcenter {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    text-align: center;
    color: $dark-text;
  }
  
  .mob-fh2 {
    font-family: $font1;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.36px;
    color: $dark-text;
  }
  
  .font1-wmd-fh4-lh1 {
    font-family: $font1;
    font-size: 22px;
    font-weight: 500;
    line-height: 0.91;
    color: $dark-text;
  }
  
  .font1-wmd-fh4-lh14 {
    font-family: $font1;
    font-size: 22px;
    font-weight: 500;
    line-height: 0.91;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wmd-fh3-lh11-tcenter {
    font-family: $font1;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wmd-fh3-lh11 {
    font-family: $font1;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: $dark-text;
  }
  
  .font1-wrg-fh3-lh155 {
    font-family: $font1;
    font-size: 18px;
    line-height: 1.56;
    color: $lighter-text;
  }
  
  .font1-wbold-fbody {
    font-family: $font1;
    font-size: 16px;
    font-weight: bold;
    color: $dark-text;
  }
  
  .font1-wbold-fbody-tcenter {
    font-family: $font1;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wmd-fbody {
    font-family: $font1;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  
  .font1-wrg-fbody-lh14-tcenter {
    font-family: $font1;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $dark-text;
  }
  
  .font1-wrg-fbody-lh175-tcenter {
    font-family: $font1;
    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    color: #454545;
  }
  
  .font1-wrg-fbody-lh14 {
    font-family: $font1;
    font-size: 16px;
    line-height: 1.5;
    color: $dark-text;
  }
  
  .font2-wrg-fxs-lh17 {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.71;
    color: #262f33;
  }
