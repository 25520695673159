/*===========================================================
 Custom Font CSS
============================================================*/
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/*===========================================================
 Common CSS
============================================================*/


/*===========================================================
 Variable
============================================================*/

$h1: 64px;

$h2: 42px;
$mh2: 30px;
$h3: 18px;
$h4: 22px;
$body: 16px;

$white: #fff;
$yellow: #edd139;
$black: #000000;
$light-blue: #72cef5;
$dust: #f6f1ef;
$lighter-text: #686868;
$dark-text: #3c3c3c;
$dark: #424242;
$bright-pink: #d44684;
$button-rollover: #2146aa;
$main: #212b36;
$light-grey: #e3e3e3;
$mild-pink: #e6798d;
$blue: #4672eb;

$font1: 'Ubuntu',
sans-serif;
$font2: 'Montserrat',
sans-serif;

/*===========================================================
 Common
============================================================*/
input {
    width: 100%;
    height: 70px;
    line-height: 70px;
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    box-shadow: 0 2px 14px 0 #e8e8e8;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
}
select{
    background-image: url("../img/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: 90% 20px;
    appearance: none;

}
textarea{
    padding-left: 20px;
    padding-top: 15px;
    height: 150px;
    width: 100%;
    border-radius: 6px;
    border: solid 1px #b9b9b9;
    @extend .font1-wrg-fbody-lh14;
    &::placeholder{
        @extend .font1-wrg-fbody-lh14;
    }
    
    &:focus{
        border: solid 1px #b9b9b9;
    }

}
address{
    @extend .font1-wrg-fh3-lh155;
    font-style: normal;
}

.tcenter {
    text-align: center;
}

// comon style
.flex {
    display: flex;
}

.align-center {
    align-items: center;
}
.space-between{
    justify-content: space-between;
}
.jcenter {
    justify-content: center;
}

.dblock {
    display: block;
}

// ==================
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-size: 17px;
    font-family: $font1;
    font-weight: 400;
    color: $dark-text;
    background: $white;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    overflow-y: hidden;
}

ul {
    padding: 0;
    margin: 0;
}


li,
li:hover,
a:hover,
.btn,
.btn:hover {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

a,
a:hover {
    text-decoration: none !important;
}

*:active,
*:focus {
    outline: none;
    border: 0;
}

img {
    max-width: 100%;
}

strong {
    font-weight: 700;
}

u {
    border-bottom: 1px solid #fff;
    line-height: 1;
    text-decoration: unset;
}

.cboth {
    overflow: hidden;
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

button {
    background-color: transparent;
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

button:active,
button:focus {
    outline: 0;
    outline: 0;
}


/*===========================================================
 Container
============================================================*/

.container-max-width {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    &--mid {
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
    }
}

.below-header {
    @media (min-width: 992px){
        margin-top: 100px;
    }
}

.bg-default-image {
    background-image: url('../img/bg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 1000px;
}


/*===========================================================
 Placeholder Style
============================================================*/

::-webkit-input-placeholder {
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.39px;
}

:-moz-placeholder {
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 300;
    line-height: 60px;
    letter-spacing: 0.39px;
}

::-moz-placeholder {
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 300;
    line-height: 60px;
    letter-spacing: 0.39px;
    padding-top: 10px;
}

:-ms-input-placeholder {
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.39px;
}





/*===========================================================
Preloader CSS
============================================================*/
.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 99999999;
}

#preloader-logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loading-msg {
    width: 100%;
    font-size: 0.75em;
    color: #555;
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
}

.spinner {
    width: 80px;
    height: 80px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid #2489CE;
    border-radius: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: spin 1s infinite ease;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#loading-msg {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 25px;
    text-align: center;
    color: #333;
    font-size: 0.8em;
}

/*===========================================================
 Scroll To Top
============================================================*/

.go-top {
    bottom: 45px;
    display: none;
    position: fixed;
    right: 0;
    z-index: 999;
}

.go-top img {
    width: 35px;
}

.go-top span {
    /*background-color: #4285F4;*/
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
    float: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 5px 10px;
}

.go-top span:hover {
    color: #fff;
}


/*===========================================================
 Navigation Menu Desktop
============================================================*/
.header-area {
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: $white;
}

.header {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.menu ul li {
    list-style: none;
    display: inline-block;
}


.logo img {
    width: 150px;
}

/*===========================================================
 Navigation Menu slideout
============================================================*/
main .mobileMenuOpener {
    display: none;
}

.panel-header {
    display: none;
}
.menu-section{
    padding-bottom: 70px;
}
.slideout-menu {
    padding-bottom: 70px;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 280px;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
}

.slideout-menu-left {
    left: 0;
}

.slideout-menu-right {
    right: 0;
}

.slideout-panel {
    position: relative;
    z-index: 1;
    background-color: #FFF;
    min-height: 100vh;

}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}

.slideout-open .slideout-menu {
    background: #34495e;
}

.btn-hamburger {
    cursor: pointer;
}

.menu-section-list {
    padding: 25px 10px;
}

.menu-section-list li a:hover {
    color: #bdc3c7;
}

.menu-section-list li a {
    line-height: 2;
    color: #fff;
}

.menu-section-list li {
    display: block;
}

.btn-hamburger img {
    width: 27px;
}

/*Hamburger Menu Icon*/
.hamburger .line {
    width: 40px;
    height: 5px;
    background-color: #34495e;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger:hover {
    cursor: pointer;
}

.hamburger:hover .line {
    opacity: .8;
}

.slideout-open .line:nth-child(2) {
    opacity: 0;
}

.slideout-open .line:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
}

.slideout-open .line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
}

.menu ul li .dropdown-menu li {
    display: block;
}

.menu ul li .dropdown-menu li a {
    padding: 5px 15px;
    line-height: 1.2;
    display: block;
}

.dropdown-menu {
    margin: 0;
    border: 0;
    border-radius: 0;
}

.dropdown a {
    position: relative;
}

.dropdown>a:hover::after {
    color: #d25419;
}

.dropdown>a::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

/*===========================================================
 
===========================================================*/





/*===========================================================
 
===========================================================*/




/*===========================================================
 Responsive Menu
===========================================================*/
@media (min-width: 992px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}

@media (max-width: 991px) {
    .header-area {
        display: none;
    }

    .mobile-logo {
        width: 100px;
    }

    .dropdown-menu {
        padding-top: 0;
    }

    .menu ul li .dropdown-menu li {
        padding-left: 10px;

    }

    .menu ul li .dropdown-menu li a {
        padding-left: 0;
        padding-right: 0;
    }

    .dropdown-menu {
        width: 100%;
        background-color: transparent;
        background-clip: padding-box;
        border: none;
        position: unset;
        top: unset;
    }

    main .mobileMenuOpener {
        display: block;
        position: absolute;
        width: 20px;
        height: 100%;
        z-index: 10;
    }

    .menu ul li {
        display: block;

    }

    .menu ul li a {
        color: #fff;
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        line-height: 2;
    }

    .menu ul li .dropdown-menu li a {
        line-height: 2;
    }

    .dropdown>a::after {
        right: 10px;
        position: absolute;
        top: 50%;
        margin-top: -1px;
    }

    /*Menu Activation*/
    .panel-header {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        -webkit-box-shadow: 0 0 0 #ddd;
        box-shadow: 0px 1px 3px #eee;
        padding: 10px 15px;
    }
}

/*===========================================================
 Responsive
============================================================*/

@media (min-width: 992px){
    .container {
        max-width: 860px;
    }
}
@media (min-width: 1199px){
    .container-lg {
        max-width: 1060px;
    }
}
@media (max-width: 1199px) {}

@media (max-width: 991px) {

}


@media (max-width: 575px) {}