.is-admin-area {
.order-header {
    display: none;
}

.btn-default {
    display: inline-block;
    border-radius: 6px;
    background: #4672eb;
    font-weight: 500;
    color: #ffffff !important;
    position: relative;
}
.btn-form-group .form-control {
    background: #4672eb;
    border: none;
    color: #ffffff;
    font-weight: 500;
    border-radius: 6px;
    text-align: center;
    text-align-last:center;
}

.alert {
    margin-bottom: 0;
}

}