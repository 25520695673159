.order-logo {
    display: block;
    margin: 0 auto;
    width: 257px;
}

.order-header {
    padding-top: 50px;
}
.row-spacing-b-20 {
    padding-bottom: 20px;
}

.btn-large {
    max-width: 525px;
    width: 100%;
    padding: 10px 30px !important;
}

.small-paragraph {
    font-size: 11px !important;
}

.c-content-page {
    padding: 50px 0;
    h1 {
        font-size: 42px;
        margin-bottom: 50px;
        color: #111111;
        font-weight: 500;
    }
    h2 {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 30px;
        color: #111111;
        font-weight: 500;
    }
    p {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 22px;
    }
    a {
        font-size: 12px;
        color: #4672EB
    }
}

.modal-content {
    h1 {
        font-size: 22px;
        margin-bottom: 50px;
        color: #111111;
        font-weight: 500;
        @media (min-width: 768px) {
            font-size: 42px;
        }
    }
    h2 {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 30px;
        color: #111111;
        font-weight: 500;
    }
    p {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 22px;
    }
    a {
        font-size: 12px;
        color: #4672EB
    }
}

.cms-divide {
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @media (min-width: 768px) {
        display: flex;
    }
}

.cms-right {
    @media (min-width: 768px) {
        padding-top: 50px;
        padding-left: 90px;
        border-left: solid 1px #e3e3e3;
    }
    height: 100%;
    h1 {
        font-size: 22px;
    }
    a {
        display: block;
        font-size: 22px;
        margin-bottom: 10px;
        color: #4672EB
    }
}

.pricing-sldier {
    left: -50px;
    position: relative;
    .slick-list {
        overflow: visible;
    }
    .slick-dots {
        position: relative;
        left: 50px;
    }
    @media (min-width: 1024px) {
        left: 0;
    }
}

.c-home {
    overflow-x: hidden;
}

.tcenter {
    text-align: center !important;
    h2 {
        text-align: center !important;
    }
}

.form-error {
    border: 1px solid red !important;
}

.loading-mask {
    position: fixed;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 1;
}
.loading-bar {
    position: fixed;
    top: 0;
    height: 5px;
    width: 100%;
    z-index: 1;
    
    .in {
      animation: fill 1s linear 1;
      height: 100%;
      background-color: $bright-pink;
    }
  }
  
  @keyframes fill {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  .form-control {
      &:disabled {
          background: #f7f7f7;
      }
  }

  .c-not-available {
      padding: 100px 0;
      h1 {
        @extend .font1-wmd-fh1-lh126;
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 30px;  
      }
  }

  .form-check-label {
      a {
          color: $blue;
      }
  }

  .ReactModal__Overlay {
      z-index: 1000;
      background:rgba(0,0,0,0.7) !important;
      .btn-close {
          float: right;
          img {
              width: 70%;
          }
      }
      .modal-content {
          margin: 20px;
          clear: both;
          width: 280px;
          height: 500px;
          overflow-y: visible;
          @media (min-width: 768px) {
            margin: 60px;
            width: 700px;
            height: 800px;
          }

          @media (min-width: 1200px) {
            margin: 60px;
            width: 1000px;
            height: 1000px;
          }
      }
  }

  .icon-dd {
      width: 80px;
      top: 3px;
      position: relative;
  }

  .icon-gc {
    width: 80px;
    top: -5px;
    position: relative;
  }

  .icon-gc-test {
      position: relative;
      top: -10px;
      font-size: 12px;
  }

  input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}