@import 'base';
@import 'mixin';
@import 'order';

body {
    @include font1-wrg-fh3-lh155;
}

.title {
    span {
        @extend .font1-wmd-fh3-lh11-tcenter;
    }

    h2,
    h3 {
        max-width: 711px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 50px;
        @extend .font1-wmd-fh2-lh123-ls5s-tcenter;
    }
}

.menu a {
    color: $dark;
    display: inline-block;
    padding: 0 45px;
    line-height: 50px;
    font-size: $body;

    &.btn-default {
        color: $white;
    }

}

.btn-default {
    display: inline-block;
    line-height: 50px;
    border-radius: 6px;
    background-color: $blue;
    font-weight: 500;
    padding: 0 30px;
    color: $white !important;
    min-width: 200px;
    position: relative;
    @extend .font1-wmd-fbody;

    &:disabled {
        background-color: $light-grey;
    }

    &:hover {
        background-color: $button-rollover;
    }
    &.is-loading {
        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            right: 10%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid #ccc;
            border-top-color: #000;
            animation: spinner .6s linear infinite;
        }
    }
}

@keyframes spinner {
    to {transform: rotate(360deg);}
  }

// Hero Area
.hero {
    &-area {
        padding: 150px 0 50px 0;

        &.pb-200 {
            padding-bottom: 200px;
        }
    }

    h1 {
        margin-bottom: 30px;
        @extend .font1-wmd-fh1-lh126;
    }

    div {
        text-align: center;
    }

    p {
        @extend .font1-wrg-fbody-lh175-tcenter;
    }

    a {
        font-weight: bold;
        color: #454545;
    }
}

// fast
.fast {
    &-area {
        border-radius: 3px;
    }

    &-bg {
        width: 100%;
        min-height: 465px;
        background-image: url("../img/rectangle.png");
        background-size: 100% 100%;
        border-bottom-left-radius: 70px;
    }

    &-text {
        margin-left: -30px;
        padding-right: 30px;
        height: 100%;
        padding-left: 90px;
        padding-top: 65px;
        background-color: $dust;

        h2 {
            margin-bottom: 20px;
            max-width: 540px;
            @extend .font1-wmd-fh2-lh123-ls5;
        }

        p {
            margin-bottom: 25px;
            max-width: 520px;
        }
    }
}

.fibre-bg {
    background-image: url("../img/fibre.png");

}

.deliver {
    &-area {
        padding-top: 170px;
        padding-bottom: 200px;
    }




    &-img {
        position: relative;
        width: 100%;

        img {
            &+img {
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }

    &-single {
        margin-bottom: 100px;

        img {
            border-bottom-left-radius: 35px;
            margin-bottom: 40px;
        }

        h4 {
            @extend .font1-wmd-fh4-lh14;
            margin-bottom: 20px;
        }

        p {
            @extend .font1-wrg-fbody-lh175-tcenter;
        }
    }
}

// trust-area
.trust {
    &-area {}

    &-left {
        background-color: $dust;
        padding-left: 60px;
        width: calc(100% - 528px);
        display: flex;
        align-items: center;

        &-inner {
            max-width: 530px;
        }

        img {
            margin-bottom: 30px;
        }

        h2 {
            margin-bottom: 30px;
            @extend .font1-wmd-fh2-lh123-ls5;
        }

        .rev {
            margin-top: 40px;

            span {
                display: inline-block;
                padding-right: 15px;
                @extend .font1-wrg-fh3-lh155;
            }
        }
    }

    &-right {
        background-color: $dust;
        max-width: 690px;
        width: 100%;
    }
}

.pricing {
    &-box {
        border: 1px solid $lighter-text;
        border-radius: 10px;
        padding: 0 20px 20px;
    }
    &-area {
        padding-top: 160px;
        padding-bottom: 120px;
    }

    margin-top: 30px;

    li {
        list-style: none;

        img {}

        span {
            display: block;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            color: $dark-text;
        }

        strong {
            font-weight: 400;
            font-size: 29px;
        }

        margin-top: 24px;

        a {
            display: block;
        }
    }

    &-top {
        border-radius: 10px;
        color: $dark;
        padding: 20px 20px 0;

        span {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.11;
        }

        h2 {
            font-size: 55px;
            font-weight: bold;
            line-height: 1;
        }

        i {
            font-size: 15px;
            line-height: 1.33;
        }
        .speed {
            padding-top: 20px;
            color: $bright-pink;
            display: block;
            font-weight: 700;
            font-size: 18px;
            @media (min-width: 768px) {
                font-size: 22px;
            }
            .small {
                font-weight: 500;
                font-size: 14px;
                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .package-heading {
            font-weight: 500;
            font-size: 14px;
        }
    }

    &-bottom {
        position: relative;


        li {
            img {
                display: inline-block;
            }
        }
    }

    &>div {
        // width: calc(33.33% - 20px);
    }

    .slick-slide {
        padding-top: 50px;
        width: 290px;
        padding-left: 15px;
        padding-right: 15px;
    }

    &-left {
        .pricing-top {
            background-color: #939393;
        }

        li {
            strong {
                color: #939393;
            }
        }
    }

    &-middle {
        .pricing-top {

            position: relative;
            z-index: 2;

            &::after {
                position: absolute;
                top: 0;
                height: 20px;
                width: 100%;
                left: 0;
                z-index: 20;
                content: "";
            }

            &::before {
                border-radius: 10px;
                background-color: $yellow;
                position: absolute;
                top: -34px;
                left: 0;
                width: 100%;
                content: "Most Popular";
                z-index: -1;
                @extend .font1-wbold-fbody-tcenter;
                padding: 5px;
                height: 50px;

            }

        }

        li {
            strong {
                color: $bright-pink;
            }
        }
    }

    &-right {
        .pricing-top {
            background-color: $blue;
        }

        .pricing-bottom {
            position: relative;

        }
    }
}

// Accordion
.accordion {
    &-area {
        padding-bottom: 150px;
    }

    &:last-child {
        .accordion__item-title {
            border-bottom: solid 1px $light-grey;
        }
    }

    &__item-title {
        float: left;
        width: 100%;
        margin-bottom: 0;
        position: relative;
        @extend .font1-wmd-fh4-lh1;
        padding: 20px 50px;
        border-top: solid 1px $light-grey;


        &:before {
            background-image: url("../img/arrow.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            left: 10px;
            top: 20px;
            transition: 400ms;
            transform: rotate(-90deg);
        }

        &.active {
            &:before {
                transition: 400ms;
                transform: rotate(0deg);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.accordion__text {
    width: 100%;
    float: left;
    padding: 0 50px;

    input {
        margin-top: 20px;
        max-width: 365px;
    }

    padding-bottom: 25px;
    padding-top: 10px;
}

.footer {
    &-area {
        padding: 18px 0;
        border-top: solid 1px $light-grey;
        margin-top: 100px;
    }

    ul {
        display: flex;
        align-items: center;

        li {
            display: inline-block;

            a {
                display: block;
                @extend .font1-wrg-fbody-lh14;
                padding: 0 18px;
            }
        }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
    align-content: center;

    &-area {}

    &-left {}

    &-right {
        max-width: 530px;
    }

    span {
        @extend .font1-wmd-fh3-lh11;
    }

    h2 {
        @extend .font1-wmd-fh2-lh123-ls5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        @extend .font1-wrg-fbody-lh14;
    }
}

// not-available-area

.not-available {
    &-area {
        padding-top: 200px;
        padding-bottom: 180px;
        background-color: $dust;
    }

    form {
        margin-top: 55px;
    }

    max-width: 820px;





    a {
        margin-top: 40px;
    }

}

.form {
    max-width: 590px;

    &-control {
        border: solid 1px #b9b9b9;
        border-radius: 6px;
        box-shadow: none;
        height: 60px;
        margin-bottom: 20px;
        padding-left: 25px;
        @extend .font1-wrg-fbody-lh14;
        width: 100%;


        &::placeholder {
            @extend .font1-wrg-fbody-lh14;
        }

        &::-webkit-input-placeholder {
            color: #6c6c6c;
            font-size: 14px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0.39px;
        }
        
        &:-moz-placeholder {
            color: #6c6c6c;
            font-size: 14px;
            font-weight: 300;
            line-height: 60px;
            letter-spacing: 0.39px;
        }
        
        &::-moz-placeholder {
            color: #6c6c6c;
            font-size: 14px;
            font-weight: 300;
            line-height: 60px;
            letter-spacing: 0.39px;
            padding-top: 10px;
        }
        
        &:-ms-input-placeholder {
            color: #6c6c6c;
            font-size: 14px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0.39px;
        }

        &.form-check-input {
            position: absolute;
            left: 0;
            top: 5px;
            z-index: 1;
            max-width: 21px;
            width: 21px;
            height: 21px;
            background-color: $dust;
        }

    }

    &-check {
        position: relative;
        max-width: 600px;

        &-label {
            @extend .font1-wrg-fbody-lh14;
            padding-left: 35px;
            position: relative;
            z-index: 1;
        }
    }
}

.contact,
.not-available {
    h2 {
        font-size: $h1;
        font-weight: 500;
        line-height: 1.27;
        letter-spacing: -1px;
        color: $dark-text;
        margin-bottom: 20px;

    }

    p {
        font-size: $body;
    }

    button {
        margin-top: 35px;
        cursor: pointer;
    }
}

.contact {
    &-area {
        padding: 220px 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    &-left {
        max-width: 500px;
    }

    &-right {
        display: flex;
        align-items: center;
        padding-left: 90px;
        border-left: solid 1px $light-grey;
        height: 100%;

    }

    p {
        margin-bottom: 10px;
    }

    a {
        font-size: $h3;
        line-height: 1.56;
        color: $blue;
    }



    h4 {
        @extend .font1-wmd-fh4-lh1;
        margin-bottom: 15px;
    }

}

.checkout {
    &-area {}

    &-left {}

    &-right {}

    h2 {
        @extend .font1-wmd-fh2-lh123-ls5;
        margin-bottom: 20px;
    }
}


.checkout {
    max-width: 605px;
    padding-right: 15px;

    &-area {}

    small {
        position: absolute;
        font-size: 10px;
        line-height: 2.4;
        color: $dark-text;
        display: inline-block;
        padding-left: 25px;
    }

    h3 {
        padding-top: 65px;
        @extend .font1-wmd-fh4-lh1;
        margin-bottom: 24px;
    }

    p {
        @extend .font1-wrg-fbody-lh14;
        margin-bottom: 38px;
        padding-bottom: 24px;
        border-bottom: solid 1px $light-grey;
    }

    select {
        max-width: 210px;
    }

    input {
        max-width: 500px;

        &.js-datepicker--dob {
            text-transform: uppercase;
            max-width: 210px;
        }
    }

    button {
        margin-top: 35px;
    }

    &-content {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
        padding-top: 230px;
        padding-left: 70px;
        padding-right: 70px;
        max-width: 494px;
        width: 100%;
        background-color: $blue;
        height: 100%;
        color: $white;

        h3 {
            font-size: $h4;
            font-weight: 500;
            margin-bottom: 20px;
        }

        li {
            list-style: none;
        }

        li,
        p,
        span {
            font-size: $body;
            line-height: 2;
        }

        hr {
            margin: 20px 0;
        }

        .times {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 11;
        }

        span {}

        small {
            font-size: $body;
            line-height: 1.5;
            color: $white;
        }
    }

    &-secure {
        max-width: 600px;
        border-top: solid 1px $light-grey;

        margin-top: 60px;
        padding-top: 35px;

        p {
            margin: 20px 0;
        }

        h4 {
            display: flex;
            align-items: center;

            span {
                display: inline-block;
                @extend .font1-wmd-fh4-lh1;

            }

            img {
                margin-right: 20px;
            }
        }
    }
}

.modal {

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $dark-text;
        border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &-header {
        border: 0;
    }

    &-title {
        font-size: 42px;
        font-weight: 500;
        letter-spacing: -0.5px;
        color: $dark-text;
        padding: 30px 0;
    }

    h3 {
        font-size: $body;
        font-weight: bold;
        line-height: 1.5;
        color: $dark-text;
        margin-bottom: 10px;
    }

    p {
        font-size: $body;
        @extend .font1-wrg-fbody-lh14;
        margin-bottom: 40px;
    }
}

.what-happened {
    max-width: 640px;

    &-area {
        padding: 200px 0 155px;

    }

    &-content {
        &.checkout-content {
            background-image: url("../img/checkout.png");
            background-color: transparent;
            background-repeat: no-repeat;
        }
    }

    h3 {
        @extend .font1-wmd-fh4-lh1;
        margin-bottom: 40px;


    }

    a {
        margin-top: 60px;
    }

    ul {
        li {
            list-style: none;
            position: relative;
            padding-left: 70px;
            margin-bottom: 25px;
            min-height: 46px;

            &.happened {
                &-1 {
                    .number {

                        background-color: $bright-pink;
                    }

                }

                &-2 {
                    .number {
                        background-color: $yellow;
                    }

                }

                &-3 {
                    .number {
                        background-color: $blue;
                    }

                }
            }

            span {
                &.number {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 46px;
                    color: $white;
                    width: 46px;
                    height: 46px;
                    font-size: $h4;
                    font-weight: 500;
                }
            }
        }
    }
}

.map {
    margin-top: 100px;

    p {
        @extend .font1-wrg-fbody-lh14-tcenter;
        margin-top: 35px;
    }
}

.pb-200 {
    padding-bottom: 200px;
}

// Responsive
@media (max-width: 1340px) {
    .trust {
        &-right {
            max-width: 540px;
        }

        &-area {
            padding: 50px 0;
            background-color: $dust;

        }
    }
}

@media (max-width: 1300px) {
    .menu a {
        padding: 0 15px;
    }
}

@media (max-width: 1280px) {
    .what-happened {
        &-content {
            &.checkout-content {
                max-width: 350px;
                background-image: url("../img/checkout.png");
                background-color: transparent;
                background-repeat: no-repeat;
            }
        }
    }
}

@media (max-width: 1199px) {
    .content-right {
        padding-left: 30px;
    }

    .checkout-content {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 300px;
    }

    .footer ul li a {
        padding: 0 10px;
    }

    .menu a {
        font-size: 15px;
    }

    .trust {
        &-right {
            max-width: 540px;
        }

        &-left {
            width: 50%;
            padding-left: 0;
        }

        &-area {
            padding: 50px 0;
            background-color: $dust;

        }
    }

    .what-happened {
        max-width: 590px;

        &-content {
            &.checkout-content {
                max-width: 300px;

            }
        }
    }
}

@media (max-width: 991px) {
    .content {
        flex-direction: column;
    }

    .content-right {
        padding: 0;
    }

    .content-left {
        text-align: center;
        margin-bottom: 25px;
    }

    .content-alt .content {
        flex-direction: column-reverse;
        padding-top: 0;

    }

    .contact {
        &-right {
            padding-left: 30px;
        }

        &-left {
            padding-right: 30px;
        }
    }

    .checkout {
        max-width: 410px;
        padding-right: 15px;
    }

    .trust {
        &-right {
            max-width: 100%;
            text-align: center;
            padding-bottom: 50px;
        }

        &-left {
            width: 100%;
            padding-left: 50px;
            padding-right: 50px;

            &-inner {
                max-width: 100%;
            }
        }

        flex-direction: column-reverse;

    }

    .what-happened {
        max-width: 590px;

        &-content {
            &.checkout-content {
                display: none;
                max-width: 300px;

            }
        }
    }

}
.social-bottom{
    position: fixed;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 260px;
    padding-left: 10px;
    padding-right: 10px;
    li{
        width: 48%;
        a{
            min-width: auto;
            width: 100%;
        }
    }
}
.footer-mobile-area {
    display: none;
    border-top: solid 1px $light-grey;

}
.footer-links{
    a{
        @extend .font1-wrg-fbody-lh14-tcenter;
        line-height: 2;

    }

}

.not-available-area {
    margin-top: 50px;
}

@media (max-width: 767px) {
    .what-happened-area{
        padding-top: 50px;
    }
    .checkout-area{
        padding-bottom: 50px;
    }
    .not-available-area {
        padding: 50px 0;
    }

    .pricing-area {
        overflow-x: hidden;
    }

    .contact {
        &-area {
            padding: 50px 0;
        }

        flex-direction: column;

        &-left {
            padding-right: 0;
        }

        &-right {
            width: 100%;
            padding-left: 0;
            border-left: 0;
            margin-top: 50px;
        }
    }

    .checkout h3 {
        padding-top: 40px;
    }

    .checkout-content {
        border-radius: 6px;

        padding-top: 15px;
        padding-bottom: 15px;
        position: unset;
        width: calc(100% - 30px);
        max-width: 510px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;

        .times {
            display: none;
        }

        h3 {
            font-size: 16px;
            position: relative;

            &.summary {
                padding-left: 40px;

                cursor: pointer;
                margin-bottom: 0;

                &::before {
                    left: 0;
                    top: 7px;
                    position: absolute;
                    content: "";
                    background-image: url("../img/arrow-down-white.svg");
                    z-index: 99;
                    width: 20px;
                    height: 20px;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
            }

        }
    }

    .summary-area {
        display: none;
        padding-top: 30px;
        &.is-open {
            display: block;
        }
    }



    body {
        font-size: 16px;
        line-height: 24px;
        overflow-x: hidden;
    }

    .trust {
        &-right {
            max-width: 100%;
            padding-bottom: 40px;
        }

        &-left {
            padding-left: 0;
            padding-right: 0;

            &-inner {
                max-width: 100%;
            }
        }

        &-area {
            padding: 40px 0;
        }
    }

    .font1-wmd-fh1-lh126,
    .hero h1 {
        font-size: 32px;
        line-height: 40px;
        @media (min-width: 768) {
            font-size: 42px;
            line-height: 1.27;
        }
    }

    .hero {
        &-area {
            padding-top: 60px;

            &.pb-200 {
                padding-bottom: 60px;
            }
        }
    }

    .contact h2,
    .not-available h2 {
        font-size: 40px;
    }

    .fast {
        &-text {
            padding-bottom: 140px;
            margin-left: 0;
            margin-right: 0;
            padding-top: 110px;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: -70px;
        }

        &-bg {
            position: relative;
            z-index: 10;
            background-color: $dust;
            min-height: 350px;
        }

        &-area {}
    }

    .font1-wmd-fh2-lh123-ls5,
    .fast-text h2,
    .trust-left h2,
    .content h2,
    .checkout h2 {
        font-size: 30px;
    }

    .title {
        text-align: left;

        h3,
        h2 {}
    }

    .font1-wmd-fh2-lh123-ls5s-tcenter,
    .title h2,
    .title h3 {
        font-size: 30px;
        text-align: left;
    }

    .deliver {
        &-area {
            padding-bottom: 50px;
            padding-top: 0;
        }

        &-single {
            margin-bottom: 50px;

            p,
            h4 {
                text-align: left;
            }
        }
    }

    .font1-wrg-fh3-lh155,
    address,
    .trust-left .rev span {
        font-size: 16px;
        line-height: 1.5;
    }

    .trust-left .rev span {
        padding-right: 4px;
    }

    .font1-wmd-fh4-lh1,
    .accordion__item-title,
    .contact h4,
    .checkout h3,
    .checkout-secure h4 span,
    .what-happened h3 {
        font-size: 16px;
    }

    .accordion {
        input {
            padding-left: 10px;
        }
    }

    .footer-area {
        display: none;
    }

    .footer-mobile-area {
        display: block;
    }

    .pricing-area {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .accordion-area {
        padding-bottom: 50px;
    }

    .footer-mobile-area {
        padding: 44px 0;
    }

    .mobile-social {
        ul {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                list-style: none;

                a {
                    padding: 0 10px;
                }
            }
        }
    }

    .footer-links {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        ul {
            &:first-child {
                opacity: 0.5;
            }

            text-align: center;

            li {
                list-style: none;
            }
        }
    }

}


@media (max-width: 575px) {
    .pricing .slick-slide {}
}